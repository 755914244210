import React from "react";
import styles from "./styles.module.scss";
import Link from "next/link";
import { setClearAllFormData, setFormType } from "@/store/slices/filterSlice";
import { useDispatch } from "react-redux";
function Footer() {
  const dispatch = useDispatch();
  return (
    // <footer className="footer">
    <footer className={styles.footer + " container-fluid"}>
      <div className={styles.container + " container"}>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="footer-item">
              <ul>
                <li>
                  <Link
                    href="/"
                    passHref
                    legacyBehavior
                  >
                    <a
                      onClick={(e) => {
                        dispatch(setFormType("advancefillter"));
                        dispatch(setClearAllFormData(""));
                      }}
                    >
                      Home
                    </a>
                  </Link>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/about-liberty-advantage/">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/request-consultation/">
                    Request Free Consultation
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/privacy/">
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.libertyadvantageplan.com/wp-content/uploads/2022/09/2022_Non-discrimination.pdf"
                    target="_blank"
                    aria-label="Anti-Discrimination Notice, opens in a new tab"
                  >
                    Anti-Discrimination Notice
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.libertyadvantageplan.com/wp-content/uploads/2022/08/2022-multi-language-interpreter-services.pdf"
                    target="_blank"
                    aria-label="Multi-Language Services, opens in a new tab"
                  >
                    Multi-Language Services
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/brokers/">
                    Agents
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="footer-item">
              <ul>
                <li>
                  <a href="https://www.libertyadvantageplan.com/providers-partners/">
                    Providers & Partners
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/provider-pharmacy-formulary-search/">
                    Pharmacy & Formulary Search
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/covid-19/">
                    Coronavirus Update
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/four-corners-of-medicare/">
                    Four Corners of Medicare
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/fraud-waste-and-abuse/">
                    Fraud, Waste, and Abuse
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="footer-item">
              <ul>
                <li>
                  <a href="https://www.libertyadvantageplan.com/member-resources/">
                    Member Resources
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/member-resources/appeals-and-grievances/">
                    Appeals and Grievances
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/member-resources/organization-determination/">
                    How to File an Organization Determination
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/member-resources/out-of-network-coverage-rules/">
                    Out of Network Coverage Rules
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/interoperability-and-patient-access/">
                    Interoperability and Patient Access
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="footer-item">
              <ul>
                <li>
                  <a href="https://www.libertyadvantageplan.com/plans/2023-liberty-medicare-advantage-nursing-home-plan/">
                    2023 Liberty Medicare Advantage Nursing Home Plan
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/plans/2023-liberty-medicare-advantage-plan/">
                    2023 Liberty Medicare Advantage Plan
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/plans/2023-liberty-medicare-dual-plan/">
                    2023 Liberty Medicare Dual Plan
                  </a>
                </li>
                <li>
                  <a href="https://www.libertyadvantageplan.com/plans/">
                    Plan Archive
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={styles.footer_banner}>
          <p
            role="heading"
            aria-level="2"
          >
            <strong>Liberty Medicare Advantage</strong>
          </p>
          <p>844-854-6884 (TTY 711)</p>
        </div>
        <div className={styles.footer_info}>
          <p>
            Liberty Medicare Advantage does not offer every plan available in
            your area. Any information we provide is limited to those plans we
            do offer in your area. Please contact Medicare.gov or 1-800-MEDICARE
            to get information of all of your options. For multi-language
            interpreter services{" "}
            <a
              href="https://www.libertyadvantageplan.com/wp-content/uploads/2023/03/Liberty_Multi_Lang_Insert.pdf"
              target="_blank"
              aria-label="Click here, opens in a new tab"
            >
              click here
            </a>
            .
          </p>
        </div>
        <div className={styles.copyright_container}>
          <p>
            ©2023 Liberty Medicare Advantage. All rights reserved. Created and
            Maintained by{" "}
            <a
              href="https://www.wsiwebsuccess.com/?utm_source=client-site"
              target="_blank"
              aria-label="WSI, opens in a new tab"
            >
              WSI.
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
