import React, { useState } from "react";
import styles from "./DropdownMenu.module.scss";
import { AiFillCaretDown } from "react-icons/ai";

const DropdownMenu = ({ menuItems, title }) => {
  const [openDropdown, setOpenDropdown] = useState(false); // Track the dropdown visibility
  const [openSubmenu, setOpenSubmenu] = useState(null); // Track which submenu is open

  // Toggle the main dropdown
  const handleDropdownToggle = () => {
    setOpenDropdown(!openDropdown);
  };

  // Toggle submenus
  const handleSubmenuToggle = (index) => {
    // If the submenu is already open, close it; otherwise, open it and close any other
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  return (
    <div className={styles.dropdownContainer}>
      <div
        className={styles.dropdownHeader}
        onClick={handleDropdownToggle}
      >
        {title}
        <AiFillCaretDown
          style={{
            marginLeft: "5px",
            fontSize: "16px",
            marginTop: "-2px",
          }}
        />
      </div>
      {openDropdown && (
        <ul className={styles.menu}>
          {menuItems.map((menuItem, index) => (
            <li
              key={index}
              className={styles.menuItem}
            >
              {menuItem.submenu ? (
                <div
                  className={styles.menuLink}
                  onClick={() => handleSubmenuToggle(index)}
                >
                  {menuItem.label}
                  <AiFillCaretDown
                    style={{
                      marginLeft: "5px",
                      fontSize: "16px",
                      marginTop: "-2px",
                    }}
                  />
                </div>
              ) : (
                <a
                  href={menuItem.href}
                  className={styles.menuLink}
                >
                  {menuItem.label}
                </a>
              )}
              {menuItem.submenu && openSubmenu === index && (
                <ul className={styles.submenu}>
                  {menuItem.submenu.map((subItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={styles.submenuItem}
                    >
                      <a
                        href={subItem.href}
                        className={styles.submenuLink}
                      >
                        {subItem.label}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
