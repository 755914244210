import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Image from "next/image";
import Alert from "@/public/assets/images/alert.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #707070",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const ExternalModal = ({ open, onClose, onConfirm, link }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="externalModal"
      >
        <div className="modalBody">
          <Image
            src={Alert}
            alt="alert"
            width={48}
            height={48}
            className=""
          />
          <h2 className="">
            You are about to leave{" "}
            <span style={{ color: "#1F428A" }}>libertyadvantageplan.com</span>
          </h2>
          <p>This link will open in a new window. Do you wish to continue?</p>
        </div>

        <div className="modalAction">
          <button
            className="return"
            variant="outlined"
            onClick={onClose}
          >
            Return to site
          </button>
          <button
            className="continue"
            variant="contained"
            onClick={onConfirm}
          >
            Continue
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ExternalModal;
