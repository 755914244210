import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
const Logo = require("../../../../public/assets/LibertyMedicareAdvantageLogo_CMYK.png");
import Image from "next/image";
import { clsx } from "clsx";
import Link from "next/link";
import { setClearAllFormData, setFormType } from "@/store/slices/filterSlice";
import { useDispatch } from "react-redux";
import { AiFillCaretDown } from "react-icons/ai";
import {
  ControlledMenu,
  MenuItem,
  SubMenu,
  useHover,
  useMenuState,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import { Button } from "@mui/material";
import DropdownMenu from "./CustomDropDown";

export default function Header() {
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div className={clsx(styles.header, "fixed-top bg-white")}>
      <div className={clsx(styles.contact_navbar)}>
        <div>
          <a href="tel:+18333541498">
            {" "}
            <span> Potential Members </span>
            <br /> <b>(833) 354-1498</b>
          </a>
        </div>
        <div>
          {" "}
          <a href="tel:+18448546884">
            {" "}
            <span> Member & Provider Services </span> <br />
            <b> (844) 854-6884 </b>
          </a>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link
            href="/"
            passHref
            legacyBehavior
          >
            <a
              className="navbar-brand"
              onClick={(e) => {
                dispatch(setFormType("advancefillter"));
                dispatch(setClearAllFormData(""));
              }}
            >
              <Image
                src={Logo}
                alt="Logo"
                className={styles.logo}
              />
            </a>
          </Link>
          <div className={styles.nav_toggle_btn}>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <div className={styles.navbar_div}>
              <ul className="navbar-nav">
                <li className={clsx(styles.nav_link, "nav-item")}>
                  <a
                    className="nav-link"
                    href="https://www.libertyadvantageplan.com/brokers/"
                    style={{ textTransform: "uppercase" }}
                  >
                    Agents
                  </a>
                </li>
                <li className={clsx(styles.nav_link, "nav-item")}>
                  <MemberMenu windowSize={windowSize} />
                </li>
                <li className={clsx(styles.nav_link, "nav-item")}>
                  <a
                    className="nav-link"
                    href="https://www.libertyadvantageplan.com/wp-content/uploads/2024/09/Pharmacy-policies.pdf"
                  >
                    Pharmacy
                  </a>
                </li>
                <li className={clsx(styles.nav_link, "nav-item dropdown")}>
                  <PlanMenu windowSize={windowSize} />
                </li>
                <li className={clsx(styles.nav_link, "nav-item dropdown")}>
                  <ProviderMenu windowSize={windowSize} />
                </li>
                <li className={clsx(styles.nav_link, "nav-item")}>
                  <a
                    className="nav-link"
                    href="https://www.libertyadvantageplan.com/request-consultation/"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

const MemberMenu = ({ windowSize }) => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({
    transition: true,
    transitionTimeout: 300,
  });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

  const menuItems = [
    {
      label: "Member Resources",
      href: "https://www.libertyadvantageplan.com/member-resources/",
    },
    {
      label: "Plan Finder",
      href: "/health-plan/result",
    },
  ];

  return (
    <>
      {windowSize?.width <= 992 ? (
        <DropdownMenu
          menuItems={menuItems}
          title={"Members"}
        />
      ) : (
        <>
          <div
            ref={ref}
            className="nav-link"
            {...anchorProps}
            style={{ cursor: "pointer", textTransform: "uppercase" }}
          >
            Members
            <AiFillCaretDown
              style={{
                marginLeft: "5px",
                fontSize: "16px",
                marginTop: "-2px",
              }}
            />
          </div>

          <ControlledMenu
            {...hoverProps}
            {...menuState}
            anchorRef={ref}
            onClose={() => toggle(false)}
            className={styles.dropdown_menubar}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                href={item.href}
                target={item?.target ? item.target : "_self"}
              >
                {item.label}
              </MenuItem>
            ))}
            {/* <MenuItem href="https://www.libertyadvantageplan.com/member-resources/">
              Member Resources
            </MenuItem>
            <MenuItem href="/health-plan/result">Plan Finder</MenuItem> */}
          </ControlledMenu>
        </>
      )}
    </>
  );
};

const PlanMenu = ({ windowSize }) => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({
    transition: true,
    transitionTimeout: 300,
  });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

  const menuItems = [
    {
      label: "2024 Plans",
      submenu: [
        {
          label: "2024 Liberty Medicare Advantage Nursing Home Plan",
          href: "https://www.libertyadvantageplan.com/plans/2024-liberty-medicare-advantage-nursing-home-plan/",
        },
        {
          label: "2024 Liberty Medicare Advantage Plan",
          href: "https://www.libertyadvantageplan.com/plans/2024-liberty-medicare-advantage-plan/",
        },
        {
          label: "2024 Liberty Medicare Dual Plan",
          href: "https://www.libertyadvantageplan.com/plans/2024-liberty-medicare-dual-plan/",
        },
      ],
    },
    {
      label: "2025 Plans",
      submenu: [
        {
          label: "2025 Liberty Medicare Advantage Nursing Home Plan",
          href: "https://www.libertyadvantageplan.com/plans/2025-liberty-medicare-advantage-nursing-home-plan/",
        },
        {
          label: "2025 Liberty Medicare Advantage Plan",
          href: "https://www.libertyadvantageplan.com/plans/2025-liberty-medicare-advantage-plan/",
        },
        {
          label: "2025 Liberty Medicare Dual Plan",
          href: "https://www.libertyadvantageplan.com/plans/2025-liberty-medicare-dual-plan/",
        },
      ],
    },
    {
      label: "Plan Archive",
      href: "https://www.libertyadvantageplan.com/plans/",
    },
  ];

  return (
    <>
      {windowSize?.width <= 992 ? (
        <>
          <DropdownMenu
            menuItems={menuItems}
            title={"Plans"}
          />
        </>
      ) : (
        <>
          <Link
            href={`https://www.libertyadvantageplan.com/plans/`}
            ref={ref}
            className="nav-link"
            {...anchorProps}
            style={{ cursor: "pointer", textTransform: "uppercase" }}
          >
            Plans
            <AiFillCaretDown
              style={{
                marginLeft: "5px",
                fontSize: "16px",
                marginTop: "-2px",
              }}
            />
          </Link>

          <ControlledMenu
            {...hoverProps}
            {...menuState}
            anchorRef={ref}
            onClose={() => toggle(false)}
            className={styles.dropdown_menubar}
          >
            {
              // Loop through the menu items and create a submenu for each
              menuItems?.map((item, index) => (
                <div key={index}>
                  {item?.submenu?.length ? (
                    <SubMenu label={item.label}>
                      {item.submenu?.map((subItem, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          href={subItem.href}
                          target={subItem?.target ? subItem.target : "_self"}
                        >
                          {subItem.label}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem
                      href={item.href}
                      target={item?.target ? item.target : "_self"}
                    >
                      {item.label}
                    </MenuItem>
                  )}
                </div>
              ))
            }
          </ControlledMenu>
        </>
      )}
    </>
  );
};

const ProviderMenu = ({ windowSize }) => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({
    transition: true,
    transitionTimeout: 300,
  });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

  const menuItems = [
    {
      label: "Provider Search",
      href: "/",
    },
    {
      label: "Providers & Partners",
      href: "https://www.libertyadvantageplan.com/providers-partners/",
    },
    {
      label: "Pharmacy Directory",
      href: "https://www.libertyadvantageplan.com/provider-pharmacy-formulary-search/",
    },
    {
      label: "Formulary Search",
      href: "/formulary",
    },
  ];

  return (
    <>
      {windowSize?.width <= 992 ? (
        <DropdownMenu
          menuItems={menuItems}
          title={"Providers"}
        />
      ) : (
        <>
          <div
            ref={ref}
            className="nav-link"
            {...anchorProps}
            style={{ cursor: "pointer", textTransform: "uppercase" }}
          >
            Providers
            <AiFillCaretDown
              style={{
                marginLeft: "5px",
                fontSize: "16px",
                marginTop: "-2px",
              }}
            />
          </div>

          <ControlledMenu
            {...hoverProps}
            {...menuState}
            anchorRef={ref}
            onClose={() => toggle(false)}
            className={styles.dropdown_menubar}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                href={item.href}
                target={item?.target ? item.target : "_self"}
              >
                {item.label}
              </MenuItem>
            ))}
            {/* <MenuItem href="/">Provider Search</MenuItem>
            <MenuItem href="https://www.libertyadvantageplan.com/providers-partners/">
              Providers & Partners
            </MenuItem>
            <MenuItem href="https://www.libertyadvantageplan.com/provider-pharmacy-formulary-search/">
              Pharmacy Directory
            </MenuItem>
            <MenuItem href="/formulary">Formulary Search</MenuItem> */}
          </ControlledMenu>
        </>
      )}
    </>
  );
};
